import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

import "../scss/project.scss"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Moment from "moment"

export default function Template({ data, pageContext }) {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <h1 className="project_title">{post.frontmatter.title}</h1>
      <div className="proj_prev_next">
        {pageContext.prev === null ? (
          <div className="noPrev"></div>
        ) : (
          <Link to={pageContext.prev}>&larr;&nbsp;Previous Project</Link>
        )}

        {pageContext.next === null ? (
          <div className="noNext"></div>
        ) : (
          <Link to={pageContext.next}>Next Project&nbsp;&rarr;</Link>
        )}
      </div>

      <Img
        fluid={post.frontmatter.image.childImageSharp.fluid}
        className="project_preview_image"
      />
      <div className="project_header">
        <div className="proj_header_infos project_type">
          <div className="proj_header_title">Project Type:&nbsp;</div>
          <div className="proj_info proj_info_type">
            {post.frontmatter.type}
          </div>
        </div>

        <div className="proj_header_infos project_date">
          <div className="proj_header_title">Release Date:&nbsp;</div>
          <div className="proj_info proj_info_date">
            {post.frontmatter.date === "Ongoing" ? (
              <>Ongoing</>
            ) : (
              Moment(post.frontmatter.date).format("MMMM YYYY")
            )}
          </div>
        </div>

        <div className="proj_header_infos project_client">
          <div className="proj_header_title">Client:&nbsp;</div>
          <div
            className="proj_info proj_info_client"
            dangerouslySetInnerHTML={{ __html: post.frontmatter.client }}
          />
        </div>

        {post.frontmatter.collaborators[0] === "none" ? null : (
          <div className="proj_header_infos project_collaborators">
            <div className="proj_header_title">Collaborators:&nbsp;</div>
            <div className="proj_info proj_info_collaborators">
              {post.frontmatter.collaborators.map((collaborator, index) => {
                // Exception for line break
                if (collaborator !== "<br/>") {
                  return (
                    <>
                      <span
                        dangerouslySetInnerHTML={{ __html: collaborator }}
                      />
                      {index ===
                      post.frontmatter.collaborators.length - 1 ? null : (
                        <>,&nbsp;</>
                      )}
                    </>
                  )
                } else {
                  return (
                    <span dangerouslySetInnerHTML={{ __html: collaborator }} />
                  )
                }
              })}
            </div>
          </div>
        )}

        {post.frontmatter.role === "none" ? null : (
          <div className="proj_header_infos project_role">
            <div className="proj_header_title">My Role:&nbsp;</div>
            <div
              className="proj_info proj_info_role"
              dangerouslySetInnerHTML={{ __html: post.frontmatter.role }}
            />
          </div>
        )}

        {post.frontmatter.link === "none" ? null : (
          <div className="proj_header_infos project_link">
            <div className="proj_header_title">Link to project:&nbsp;</div>
            <div className="proj_info proj_info_link">
              <a
                href={post.frontmatter.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {post.frontmatter.link}
              </a>
            </div>
          </div>
        )}
      </div>
      <div
        className="project_content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <br />
      <br />
      <div className="proj_prev_next">
        {pageContext.prev === null ? (
          <div className="noPrev"></div>
        ) : (
          <Link to={pageContext.prev}>&larr; Previous Project</Link>
        )}

        {pageContext.next === null ? (
          <div className="noNext"></div>
        ) : (
          <Link to={pageContext.next}>Next Project &rarr;</Link>
        )}
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        type
        role
        image {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
        client
        author
        collaborators
        link
      }
    }
  }
`
/*
const data = useStaticQuery(graphql`
  query WorkIndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            type
            image {
              childImageSharp {
                fluid(maxWidth: 950) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            author
          }
        }
      }
    }
  }
`)*/
